.swal2-styled.swal2-confirm {
    border-radius: 8px !important;
    background-color: #103996 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}

/*.MuiBackdrop-root{
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.99) 0%, rgba(22, 28, 36, 1) 100%) !important;
}*/

.MuiModal-root{
    z-index: 10 !important;
}

.MuiSelect-select{
    padding: 7.5px 14px !important;
}

.MuiTableSortLabel-icon, .MuiTableSortLabel-root{
    color: white !important;
}

.tabsDivider:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 20%;
    height: 30px;
    width: 50%;
    border-right:1px solid #919eab3d;
  }

.MuiButton-contained{
    color: white;
}

@keyframes blinkCursor {
  50% {
    border-right-color: transparent;
  }
}

@keyframes typeAndDelete {
  0%,
  10% {
    width: 0;
  }
  45%,
  55% {
    width: 6.2em;
  } /* adjust width based on content */
  90%,
  100% {
    width: 0;
  }
}

.terminal-loader {
  border: 0.1em solid #333;
  background-color: #1a1a1a;
  color: #0f0;
  font-family: "Courier New", Courier, monospace;
  font-size: 1em;
  padding: 1.5em 1em;
  width: 90%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.terminal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1.5em;
  background-color: #333;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.terminal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5em;
  background-color: #7c7c7c;
  /*border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
  padding: 0 0.4em;
  box-sizing: border-box;
}

.terminal-controls {
  float: right;
}

.control {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  margin-left: 0.4em;
  border-radius: 50%;
  background-color: #777;
}

.control.close {
  background-color: #e33;
}

.control.minimize {
  background-color: #ee0;
}

.control.maximize {
  background-color: #0b0;
}

.terminal-title {
  float: left;
  line-height: 1.5em;
  color: #eee;
}

.text {
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  /*animation: typeAndDelete 4s steps(11) infinite,
    blinkCursor 0.5s step-end infinite alternate; */
  margin-top: 1.5em;
  padding-bottom: 15px
}
